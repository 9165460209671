<template>
  <b-row class="match-height">
    <b-col
      md="6"
      lg="4"
    >
      <b-card
        :img-src="require('@/assets/images/flags/madrid.png')"
        img-alt="Madrid"
        img-top
        title="Madrid"
      >

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          :to="{ name: 'expedientes-list-ccaa', params: {ccaa: 'MD' } }"
        >
          Abrir Madrid
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BButton, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
}
</script>

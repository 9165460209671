<template>
  <div>
    <list-ccaa v-if="ccaa" />
    <list-cards v-else />
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import ListCards from './ListCards.vue'
import ListCcaa from './ListCcaa.vue'
import useExpedientes from './useExpedientes'

export default {
  components: {
    ListCards,
    ListCcaa,
  },
  setup() {
    const {
      expedientes,
      inquilinos,
      ccaa,
      fetchBasicData,
    } = useExpedientes()

    onMounted(() => fetchBasicData())

    return {
      expedientes,
      inquilinos,
      ccaa,
      fetchBasicData,
    }
  },
}
</script>

<style>

</style>
